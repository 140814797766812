import { DateRangePickerValue } from '@mantine/dates';

export enum IdeaDatabaseTemplateType {
  TELL = 'TELL',
  INSKRU = 'INSKRU',
  CLIP_FILE = 'CLIP_FILE',
}
export enum IdeaDatabaseInskruSelectedType {
  AWARENESS = 'awareness',
  COLLABORATION = 'collaboration',
  CREATIVE_THINKING = 'creative-thinking',
  CRITICAL_THINKING = 'critical-thinking',
  GRIT = 'grit',
  GROWTH_MINDSET = 'growth-mindset',
  PARTICIPATION = 'participation',
  LEARNING_ENVIRONMENT = 'learning-environment',
  RELATIONSHIP = 'relationship',
  SUPPORTIVE_RELATIONSHIP = 'supportive-relationship',
  DELETED_SELECTED = 'deleted-selected',
}

export enum SortType {
  LATEST_PUBLISHED = 'LATEST_PUBLISHED',
  TOP_VIEW = 'TOP_VIEW',
  TOP_COMMENT = 'TOP_COMMENT',
  TOP_REVIEW = 'TOP_REVIEW',
  TOP_DOWNLOAD = 'TOP_DOWNLOAD',
  TOP_SAVED = 'TOP_SAVED',
  TOP_INSPIRE_REACTION = 'TOP_INSPIRE_REACTION',
  TOP_FLOWER_REACTION = 'TOP_FLOWER_REACTION',
}

export enum IdeaInskruSelectedType {
  PARTICIPATION = 'participation',
  LEARNING_ENVIRONMENT = 'learning-environment',
  RELATIONSHIP = 'relationship',
  SUPPORTIVE_RELATIONSHIP = 'supportive-relationship',
  DELETED_SELECTED = 'deleted-selected',
}

export enum IdeaRecommendType {
  ALL = 'ALL',
  YES = 'YES',
  NO = 'NO',
}

export enum TypeOfIdea {
  PUBLISHED = 'PUBLISHED',
  DELETED = 'DELETED',
}
export default class IdeaDatabaseSearch {
  ideaTitle: string = '';
  typeOfIdea: TypeOfIdea = TypeOfIdea.PUBLISHED;
  alias: string = '';
  sort: SortType = SortType.LATEST_PUBLISHED;
  isRecommend: IdeaRecommendType = IdeaRecommendType.ALL;
  inskruSelected: string[] = [];
  templateType: string[] = [];
  userTag: string[] = [];
  occupation: string[] = [];
  template: string[] = [];
  publishedDateStart: string | undefined = undefined;
  publishedDateEnd: string | undefined = undefined;
  dates: DateRangePickerValue | [null, null] = [null, null];
  sizes: number = 10;
  pages: number = 1;
  isSearch: boolean = true;
}

export interface IdeaDatabaseSubmit {
  ideaTitle?: string;
  typeOfIdea: TypeOfIdea;
  alias?: string;
  sort?: SortType;
  isRecommend?: IdeaRecommendType;
  inskruSelected?: string[];
  templateType?: string[];
  userTag?: string[];
  occupation?: string[];
  publishedDateStart?: string | undefined;
  publishedDateEnd?: string | undefined;
  sizes: number;
  pages: number;
}

export enum IdeaDatabaseActionType {
  SET_IDEA_TITLE = 'SET_IDEA_TITLE',
  SET_ALIAS = 'SET_ALIAS',
  SET_SORT = 'SET_SORT',
  SET_IS_RECOMMEND = 'SET_IS_RECOMMEND',
  SET_INSKRU_SELECTED = 'SET_INSKRU_SELECTED',
  SET_TEMPLATE_TYPE = 'SET_TEMPLATE_TYPE',
  SET_OCCUPATION = 'SET_OCCUPATION',
  SET_PUBLISHED_DATE_START = 'SET_PUBLISHED_DATE_START',
  SET_PUBLISHED_DATE_END = 'SET_PUBLISHED_DATE_END',
  SET_USER_TAGS = 'SET_USER_TAGS',
  SET_TYPE_OF_IDEA = 'SET_TYPE_OF_IDEA',
  SET_PAGE = 'SET_PAGE',
  CLICK_SEARCH = 'CLICK_SEARCH',
  DATES = 'DATES',
  CLEAR = 'CLEAR',
}

export type IdeaDatabaseAction =
  | { type: IdeaDatabaseActionType.SET_IDEA_TITLE; payload: string }
  | { type: IdeaDatabaseActionType.SET_ALIAS; payload: string }
  | { type: IdeaDatabaseActionType.SET_SORT; payload: SortType }
  | { type: IdeaDatabaseActionType.SET_IS_RECOMMEND; payload: IdeaRecommendType }
  | { type: IdeaDatabaseActionType.SET_INSKRU_SELECTED; payload: string[] }
  | { type: IdeaDatabaseActionType.SET_TEMPLATE_TYPE; payload: string[] }
  | { type: IdeaDatabaseActionType.SET_OCCUPATION; payload: string[] }
  | { type: IdeaDatabaseActionType.SET_PUBLISHED_DATE_START; payload: string }
  | { type: IdeaDatabaseActionType.SET_PUBLISHED_DATE_END; payload: string }
  | { type: IdeaDatabaseActionType.SET_USER_TAGS; payload: string[] }
  | { type: IdeaDatabaseActionType.SET_PAGE; payload: number }
  | { type: IdeaDatabaseActionType.CLICK_SEARCH; payload: boolean }
  | { type: IdeaDatabaseActionType.DATES; payload: DateRangePickerValue }
  | { type: IdeaDatabaseActionType.CLEAR; payload?: undefined }
  | { type: IdeaDatabaseActionType.SET_TYPE_OF_IDEA; payload: TypeOfIdea };

export type IdeaDatabaseDispatch = (action: IdeaDatabaseAction) => void;

export interface IdeaResult {
  _id: string;
  ideaId: string;
  title: string;
  alias: string;
  name: string;
  ownerId: number;
  publishedTime: string;
  subjects: null;
  type: string;
  viewCount: number;
  commentCount: number;
  inspireCount: number;
  downloadCount: number;
  shareCount: number;
  saveCount: number;
  flowerCount: number;
  ownerName: string;
  ownerImage: string;
  ownedIdeaID: null;
  username: string;
  tags: string[];
  isHighlight: boolean;
  inskruSelected: string;
  templateType: string;
  picture: string;
  updatedTime: string;
  occupation: string;
  totalDownload: number;
  linkIdea: string;
}

export interface IdeaInskruSelectedReq {
  selectedType: IdeaInskruSelectedType;
}

export interface IdeaDetailFile {
  type: string;
  size: number;
  name: string;
  url: string;
  downloaded: number;
}

export interface ImagesProps {
  image: string;
  thumbnail: string;
  thumbnailWebp: string;
  original?: string;
}

export enum TemplateType {
  TELL = 'TELL',
  INSKRU = 'INSKRU',
  CLIP_FILE = 'CLIP_FILE',
}

export interface IdeaDetailRes {
  _id: string | undefined;
  ownerId: number | undefined;
  title: string;
  description: string;
  requestOpinion: boolean;
  tags: string[];
  images: ImagesProps[];
  files: IdeaDetailFile[];
  templateType: TemplateType | null;
  content: string;
  isDraft: boolean;
  type: string;
  creditTo: number | null;
  purposes: string;
  process: string;
  consequence: string;
  suggestion: string;
}

export interface UserSuggestResponse {
  userId: number;
  name: string;
  alias: string;
  picture: string;
}

import bannersService from '@api/banners/banners.service';
import { Button, Image, Paper, Text, Title } from '@mantine/core';
import { Banner, BannerSeq } from '@models/Banner';
import { APIResponseError } from '@models/Error';
import { IconPencil, IconTrash } from '@tabler/icons-react';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { clone } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-right',
  iconColor: 'white',
  showConfirmButton: false,
  customClass: {
    popup: 'insku-toast',
  },
  timer: 1500,
  timerProgressBar: true,
});

const BannerPage = (): React.JSX.Element => {
  const navigate = useNavigate();
  const [banners, setBanners] = useState<Banner[]>([]);

  useEffect(() => {
    bannersService.list().subscribe((resp: Banner[]) => setBanners(resp));
  }, []);

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;

    const newData = clone(banners);
    const [removed] = newData.splice(source.index, 1);
    newData.splice(destination.index, 0, removed);
    newData.forEach((b, i) => (b.seq = i + 1));
    setBanners(newData);

    const data: BannerSeq[] = newData.map(({ id, seq }) => new BannerSeq({ id, seq }));
    bannersService.updateSeq(data).subscribe({
      next: () =>
        Toast.fire({
          icon: 'success',
          title: 'บันทึกสำเร็จ',
        }),
      error: (err: AxiosError<APIResponseError>) =>
        Toast.fire({
          icon: 'error',
          title: err.response?.data?.error?.message,
        }),
    });
  };

  const Status = (start: Date | undefined, expired: Date | undefined): JSX.Element => {
    const today = dayjs();
    if (today.isBefore(start)) {
      return <Text className="text-secondary">PENDING</Text>;
    } else if (expired && today.isAfter(expired)) {
      return <Text className="text-error">EXPIRED</Text>;
    }

    return <Text className="text-success-300">ACTIVE</Text>;
  };

  const handleClickDelete = (item: Banner): void => {
    Swal.fire({
      title: 'ลบ Banner?',
      text: `ลบ Banner ลำดับที่ ${item.seq} - ${item.description}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FF4E00',
      confirmButtonText: 'ลบ',
      cancelButtonText: 'ไม่',
    }).then((result) => {
      if (result.isConfirmed) {
        if (item.id) {
          bannersService.remove(item.id).subscribe({
            next: () => {
              const b = clone(banners);
              const newBanners = b.filter((i) => i.id !== item.id);
              setBanners(() => newBanners);
            },
            error: (err: AxiosError<APIResponseError>) => {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: err.response?.data?.error?.message,
              });
            },
          });
        }
      }
    });
  };

  return (
    <div className="grid gap-4">
      <div className="mb-6">
        <article className="prose">
          <h1 className="mb-0">จัดการ Banner</h1>
        </article>
        <span className="text-error">
          **อย่าลืมย่อขนาดไฟล์ให้เล็กที่สุดเท่าที่จะเป็นไปได้เพื่อคุณภาพในการโหลดด้วยน้า ไม่งั้นภาพไม่โหลดจ้า
        </span>
      </div>

      <Link to="/banners/create" className="w-fit">
        <Button className="rounded-full bg-primary px-4 text-xl font-normal text-black no-underline hover:bg-primary-300">
          เพิ่ม Banner
        </Button>
      </Link>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="myDoppableId">
          {(provided) => (
            <div className="grid gap-4" {...provided.droppableProps} ref={provided.innerRef}>
              <Title order={2} className="font-pridi">
                แบนเนอร์ที่แสดงผลอยู่
              </Title>

              {banners.map((item, index) => (
                <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                  {(provided) => (
                    <Paper
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      shadow="xs"
                      radius="lg"
                      withBorder
                      className="flex w-fit gap-4 p-4"
                    >
                      <Image src={item.image} height={60} width={100} />

                      <div className="grid w-80 gap-2">
                        <Text>{item.description || '-'}</Text>
                        <div className="flex items-center gap-2 text-sm">
                          <Text className="font-light">ลำดับที่: </Text>
                          {item.seq}
                          <Text className="font-light">・Status: </Text>
                          {Status(item.startDate, item.expireDate)}
                        </div>
                      </div>

                      <div className="flex items-end gap-2">
                        <div
                          className="cursor-pointer rounded-2xl border bg-primary-100 px-4 py-1"
                          onClick={() => navigate(`/banners/${item.id}`)}
                        >
                          <IconPencil color="#0C2B66" className="size-6" />
                        </div>
                        <div className="cursor-pointer rounded-2xl border bg-primary-100 px-4 py-1" onClick={() => handleClickDelete(item)}>
                          <IconTrash color="red" className="size-6" />
                        </div>
                      </div>
                    </Paper>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default BannerPage;
